/**
 * Timetastic
 * Microsoft Teams page
 */
import React, { useEffect } from "react"
import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import FreeTrialAlt from "src/components/freeTrialAlt"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/slack.scss"

// SEO
const title = "Timetastic and Microsoft Teams"
const description =
  "Timetastic + Microsoft Teams - Timetastic Teams integration."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-slack-main">
          <section className="c-section u-text-centre c-slack-hero">
            <div className="u-inner c-slack-hero__inner">
              <div className="is-unanimated">
                <h1 className="h h2">
                  Timetastic + Microsoft Teams
                  <p className="h3 c-slack-hero__subheader">
                    a{" "}
                    <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                      nicer
                    </span>{" "}
                    way to book time off
                  </p>
                </h1>
              </div>
              <div className="c-slack-hero__logos">
                <img
                  loading="eager"
                  alt="Microsoft Teams"
                  className="c-slack-hero__logo is-unanimated c-teamslogo"
                  src="/images/teams/microsoft-teams.svg"
                />
                <span className="c-plus c-slack-hero__logo">+</span>
                <img
                  loading="eager"
                  alt="Timetastic"
                  className="c-slack-hero__logo is-unanimated c-timetasticlogo"
                  height="192"
                  src="/images/Timetastic-Logo-Black-Small.svg"
                  width="192"
                />
              </div>
            </div>
          </section>

          <section className="c-section c-features-gradient-blob">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated u-text-center u-text-left-mobile">
                <h2 className="h h2">
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    Manage
                  </span>{" "}
                  your absence planning <br /> from Teams
                </h2>
              </div>
              <div className="c-slack-screenshots">
                <div className="c-slack-screenshots--request">
                  <p className="u-text-center">
                    Deal with requests via Teams chat
                  </p>
                  <img
                    loading="lazy"
                    alt="Teams absence approval request"
                    className="is-unanimated gatsby-image-wrapper"
                    height="377"
                    src="/images/teams/leave-request.png"
                    width="588"
                  />
                </div>
                <div className="c-slack-screenshots--summary">
                  <p className="u-text-center">
                    Get a daily summary of who's not in today
                  </p>
                  <img
                    loading="lazy"
                    alt="Teams daily absence summary"
                    className="is-unanimated gatsby-image-wrapper"
                    height="377"
                    src="/images/teams/daily-summary.png"
                    width="588"
                  />
                </div>
              </div>
              <div>
                <div className="u-inner is-unanimated u-text-center u-text-left-mobile">
                  <h2 className="h h2 u-text-center u-text-left-mobile c-feature-points-title">
                    That's just the Teams features!
                  </h2>
                  <p className="u-font-size-s">
                    Timetastic is a full web app for managing staff leave and
                    requesting time off work. You get absence summaries,
                    reports, mobile apps, integrations with Google and Outlook
                    Calendars
                  </p>
                  <Link
                    className="u-em-link u-hover-link"
                    to="/how-it-works/"
                    onClick={() => {
                      trackCta("TeamsHowItWorks")
                    }}
                  >
                    See how Timetastic works
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="c-section c-slack-install c-free-trial--gradient-bottom">
            <div className="u-inner u-inner--m is-unanimated u-text-center u-text-left-mobile">
              <h2 className="h h3 u-text-center u-text-left-mobile">
                Add Timetastic to Teams in 4 steps:
              </h2>
              <div className="c-teams-steps--outer">
                <ol className="c-teams-steps">
                  <li>Create an account with Timetastic</li>
                  <li>Go to Settings &gt; Integrations</li>
                  <li>Click "Connect Teams" to install our app in Teams</li>
                  <li>Connect your Team to your Timetastic account</li>
                </ol>
              </div>
            </div>
          </section>

          <FreeTrialAlt
            body="Join over 8000 other companies already using Timetastic"
            buttonCopy="Start a free trial today"
            title="Create your Timetastic account"
            trackCtaLabel="TeamsSignupFooter"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
